import React from "react";
import {
  Box,
  Heading,
  Stack,
  Container,
  Flex,
  chakra,
  Spacer,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";

const ServiceCard = ({ title, imageData, ctaLink = "#" }) => {
  return (
    <Box
      bg={"white"}
      shadow="md"
      rounded="lg"
      position="relative"
      as={GatsbyLink}
      mt={"1rem"}
      target="__blank"
      to={ctaLink}
      width={"340px"}
      sx={{
        transform: "translateY(0)",
        transition: "0.5s transform",
        "&:hover": {
          transform: "translateY(-5px)",
          textDecor: "none",
          shadow: "xl",
        },
      }}
    >
      <Box
        height={"200px"}
        overflow={"hidden"}
        roundedTop="lg"
        filter={"brightness(100%)"}
        sx={{
          img: {
            width: "100%",
          },
          "&:hover": {
            filter: "brightness(80%)",
          },
        }}
      >
        <GatsbyImage image={getImage(imageData)} />
      </Box>

      <Box px={4} py={2}>
        <chakra.h2
          color={"blue.800"}
          fontWeight="bold"
          fontSize="2xl"
          textTransform="uppercase"
          textAlign={"center"}
          color="#3182ce"
        >
          {title}
        </chakra.h2>
        <Spacer />
      </Box>
    </Box>
  );
};

const Services = () => {
  const queryData = useStaticQuery(graphql`
    query ServicesImages {
      allFile(
        filter: { relativeDirectory: { eq: "services" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const ImageData = queryData.allFile.edges;
  ImageData[0].ctaLink = "/certifications";
  ImageData[1].ctaLink = "/trainings";

  return (
    <Box>
      <Container
        maxW={"7xl"}
        py={10}
        marginTop={"1rem"}
        as={Stack}
        spacing={12}
        align="center"
      >
        <Box spacing={0} align={"center"}>
          <Heading
            color="#3182ce"
            _after={{
              content: '""',
              display: "block",
              marginTop: "0.25rem",
              width: "5rem",
              height: "0.25rem",
              backgroundColor: "#2b2f69",
            }}
          >
            Our Services
          </Heading>
        </Box>
        <Flex wrap={"wrap"} w={"100%"} justify={"space-evenly"}>
          {ImageData.map((image, i) => (
            <ServiceCard
              title={image.node.name}
              imageData={image.node}
              ctaLink={image.ctaLink}
              key={i}
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Services;
