import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  // Link,
} from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import { Link as GatsbyLink } from "gatsby";
// import { motion, useTransform, useMotionValue } from "framer-motion";
import CorperateImage from "../images/corperate.png";

const SliderContent = [
  {
    title: {
      title1: "Adding Value to",
      title2: "Your Business",
    },
    CallToAction: {
      Text: "Contact Us",
      Link: "/contact-us",
    },
    imageSrc: CorperateImage,
  },
  // //{
  //   title: {
  //     title1: "Adding Value to",
  //     title2: "Your Business",
  //   },
  //   CallToAction: {
  //     Link: "#",
  //   },
  //   imageSrc:
  //     "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=800&q=80",
  // //},
];

const sliderSetting = {
  dots: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Hero() {
  return (
    <Box>
      <Container maxW={"7xl"}>
        <Slider {...sliderSetting}>
          {SliderContent.map((content, i) => (
            <div key={i}>
              <Stack
                align={"center"}
                spacing={{ base: 8, md: 10 }}
                py={{ base: 12, md: 16 }}
                px={"10"}
                direction={{ base: "column", md: "row" }}
              >
                <Stack
                  flex={1}
                  spacing={{ base: 5, md: 8 }}
                  textAlign={{ base: "center", md: "left" }}
                  align={{ base: "center", md: "start" }}
                >
                  <Heading
                    lineHeight={1.3}
                    fontWeight={600}
                    fontSize={{ base: "5xl" }}
                  >
                    <Text as={"span"} position={"relative"}>
                      {content.title.title1}
                    </Text>
                    <br />
                    <Text as={"span"} color={"blue.400"}>
                      {content.title.title2}
                    </Text>
                  </Heading>
                  <Button
                    as={GatsbyLink}
                    to={content.CallToAction.Link}
                    rounded={"full"}
                    size={"lg"}
                    m={{ base: "auto", md: "0" }}
                    fontWeight={"normal"}
                    px={6}
                    colorScheme={"blue"}
                    bg={"blue.400"}
                    _hover={{ bg: "blue.500" }}
                  >
                    {content.CallToAction.Text || "Know More"}
                  </Button>
                </Stack>
                {/* </motion.div> */}
                <Flex
                  flex={1}
                  justify={"center"}
                  align={"center"}
                  position={"relative"}
                  w={"full"}
                >
                  <Blob
                    w={"150%"}
                    h={"150%"}
                    position={"absolute"}
                    top={"-20%"}
                    left={0}
                    zIndex={-1}
                    color={"blue.50"}
                  />
                  {/* <motion.div
                  animate={{ scale: [0, 1] }}
                  transition={{ duration: 1 }}
                > */}
                  <Box
                    position={"relative"}
                    height={"350px"}
                    rounded={"2xl"}
                    boxShadow={"2xl"}
                    width={"full"}
                    overflow={"hidden"}
                  >
                    <Image
                      alt={"Hero Image"}
                      width={"100%"}
                      height={"100%"}
                      fit={"cover"}
                      src={content.imageSrc}
                    />
                  </Box>
                  {/* </motion.div> */}
                </Flex>
              </Stack>
            </div>
          ))}
        </Slider>
      </Container>
    </Box>
  );
}

export const Blob = (props) => {
  return (
    <Icon
      width={"100%"}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
