import React from "react";
import { Box, Heading, Stack, Collapse, Button } from "@chakra-ui/react";
import Container from "../components/PageContentContainer";
import { htmlSerializer } from "../utils/htmlSerializer";
import { RichText } from "prismic-reactjs";
import { useStaticQuery, graphql } from "gatsby";

export default function AboutUs() {
  const [show, setShow] = React.useState(false);

  const handleToggle = () => setShow(!show);
  const queryResult = useStaticQuery(graphql`
    query AboutUsContent2 {
      prismicAboutUs {
        data {
          content {
            richText
          }
        }
      }
    }
  `);

  const richText = queryResult.prismicAboutUs.data.content.richText;
  return (
    <div id="about-us">
      <Box>
        <Container
          maxW={"7xl"}
          py={10}
          marginTop={"1rem"}
          as={Stack}
          spacing={12}
          align="center"
        >
          <Box spacing={0} align={"center"}>
            <Heading>About Us</Heading>
          </Box>
          <Collapse startingHeight={"10.5rem"} in={show}>
            <RichText render={richText} htmlSerializer={htmlSerializer} />
          </Collapse>
          <Button size="sm" onClick={handleToggle}>
            Show {show ? "Less" : "More"}
          </Button>
        </Container>
      </Box>
    </div>
    
  );
}
