import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import AccrediatedBy from "../components/AccrediatedBy";
import Testimonials from "../components/Testimonials";
import Services from "../components/Services";
import OurClients from "../components/OurClients";
import AboutUs from "../components/AboutUs";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>BSCIC Certifications Management Systems </title>
      </Helmet>
      <Hero />
      <AboutUs />
      <AccrediatedBy />
      <Services />
      <OurClients />
      <Testimonials />
    </Layout>
  );
};

export default IndexPage;
