import React from "react";
import { Box, Heading, Stack, Container, Flex } from "@chakra-ui/react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export default function AccrediatedBy() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "accrediationLogo" } }) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  `);
  const images = data.allFile.nodes;
  return (
    <Box>
      <Container
        maxW={"7xl"}
        py={10}
        marginTop={"1rem"}
        as={Stack}
        spacing={12}
        align="center"
      >
        <Box spacing={0} align={"center"}>
          <Heading
            color="#3182ce"
            _after={{
              content: '""',
              display: "block",
              marginTop: "0.25rem",
              width: "5rem",
              height: "0.25rem",
              backgroundColor: "#2b2f69",
            }}
          >
            Accrediated by
          </Heading>
        </Box>
        <Flex spacing={24} wrap={"wrap"} justify={"space-evenly"} width="100%">
          {images.map((image, i) => (
            <Slide key={i}>
              {/* <Image
                src={`/accrediationLogo/${name}.png`}
                alt={`name`}
                h={"100%"}
              /> */}
              {/* <GatsbyImage image={image.childImageSharp.gatsbyImageData} /> */}
              <GatsbyImage image={getImage(image)} alt={image.name} />
            </Slide>
          ))}
        </Flex>
      </Container>
    </Box>
  );
}
